import { handleError, handleResponse } from 'data/handleResponse'
import { UserProfile } from 'types'

export interface ValidationError {
  field: string
  message: string
}

export interface SuccessResponse {
  valid: true
}

export interface ErrorResponse {
  valid: false
  errors: ValidationError[]
}

export type ApiResponse = SuccessResponse | ErrorResponse

// Response type from typescript
// eslint-disable-next-line no-undef
export type FetchResponse = Response

export type ParsedResponse<T> = {
  result?: ApiResponse
  response: T | null
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function apiPOST<T>(
  uri: string,
  data: unknown,
  token?: string
): Promise<ParsedResponse<T>> {
  // eslint-disable-next-line no-undef
  const headers = new Headers()
  headers.set('Content-Type', 'application/json')

  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  // eslint-disable-next-line no-undef
  return fetch(uri, {
    mode: 'same-origin',
    method: 'POST',
    body: JSON.stringify(data),
    headers
  })
    .then((resp) => handleResponse<T>(resp))
    .catch((e) => handleError(e))
}

export async function apiGET<T>(
  uri: string,
  token?: string
): Promise<ParsedResponse<T>> {
  // eslint-disable-next-line no-undef
  const headers = new Headers()

  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  // eslint-disable-next-line no-undef
  return fetch(uri, {
    mode: 'same-origin',
    method: 'GET',
    headers,
    credentials: 'include'
  })
    .then((resp) => handleResponse<T>(resp))
    .catch((e) => handleError(e))
}

//
//
// Sign Up

export interface UserData {
  salutation: string
  firstname: string
  lastname: string
  username: string
  email: string
  password: string
  birthDate?: Date
  currentClub?: string
  roles?: string[]
  organization?: string
  newsletter?: boolean
  commercialPurposesAccepted?: boolean
  favoritePlayer?: string
  favoriteNationalPlayer?: string
  clothingSize?: string
  registerReferrer?: string
}

export type SignUpResponse = ApiResponse & { jwt?: string }

export const postSignUp = async (data: UserData): Promise<SignUpResponse> => {
  const resp = await apiPOST('/a/accounts/1/register-with-password', data)
  if (resp.result) {
    // @ts-ignore
    return { ...resp.result, jwt: resp.response?.jwt }
  }

  return { valid: true }
}

export const updateProfile = async (
  data: UserData,
  token?: string
): Promise<ApiResponse> => {
  const resp = await apiPOST('/a/accounts/1/profile/update', data, token)
  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

//
//
// Email Confirmation Verify

export type EmailConfirmationVerifyResponse = ApiResponse

export const postEmailConfirmationVerify = async ({
  token
}: {
  token: string
}): Promise<ApiResponse> => {
  const resp = await apiPOST('/a/accounts/1/verify_email/verify', null, token)

  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

export const postEmailConfirmationResend = async ({
  token
}: {
  token: string
}): Promise<ApiResponse> => {
  const resp = await apiPOST('/a/accounts/1/verify_email/resend', null, token)

  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

//
//
// Reset password request

export interface ResetPasswordRequestData {
  email: string
}

export type ResetPasswordRequestResponse = ApiResponse

export const postResetPasswordRequest = async (
  data: ResetPasswordRequestData
): Promise<ResetPasswordRequestResponse> => {
  // can only return status 2xx or system errors
  const resp = await apiPOST('/a/accounts/1/reset-password/request', data)

  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

//
//
// Reset password verify

export type ResetPasswordVerifyResponse = ApiResponse

export const getResetPasswordVerify = async ({
  token
}: {
  token: string
}): Promise<ResetPasswordVerifyResponse> => {
  // can return status 2xx, 401 (for invalid token) or system errors
  const resp = await apiGET('/a/accounts/1/reset-password/verify', token)

  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

//
//
// Reset password update

export interface ResetPasswordUpdateData {
  password: string
}

export type ResetPasswordUpdateResponse = ApiResponse

export const postResetPasswordUpdate = async (
  token: string,
  data: ResetPasswordUpdateData
): Promise<ResetPasswordUpdateResponse> => {
  // can return status 2xx, 401 (for invalid token), 422 (for invalid password) or system errors
  // status 422 has `errors` in response data (mapping?)
  const resp = await apiPOST('/a/accounts/1/reset-password/apply', data, token)

  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

//
//
// Login with password

export interface LoginWithPasswordData {
  email: string
  password: string
}

export type LoginWithPasswordResponse = ApiResponse

export const postLoginWithPassword = async (
  data: LoginWithPasswordData
): Promise<LoginWithPasswordResponse> => {
  // can return status 2xx, 422 (for invalid password) or system errors
  // status 422 has `errors` in response data (mapping?)
  const resp = await apiPOST('/a/accounts/1/login-with-password', data)

  if (resp.result) {
    return resp.result
  }

  // TODO : return token/session stuff
  return { valid: true }
}

export const getUserProfile = async (
  token: string | undefined,
  force: boolean
): Promise<UserProfile | null> => {
  let url = '/a/accounts/1/me'

  if (force) {
    // Add the current timestamp to force reload it, as it's cached otherwise
    const timestamp = new Date().getTime()
    url = url + '?ts=' + timestamp

    console.log('url', url)
  }

  const resp = await apiGET<UserProfile>(url, token)
  if (resp.response) {
    const data = resp.response
    if (data.birthDate) {
      data.birthDate = formatDate(new Date(data.birthDate))
    }
    return data
  }
  return null
}

function formatDate(date: Date): string {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const formattedMonth = month < 10 ? `0${month}` : month
  const formattedDay = day < 10 ? `0${day}` : day

  return `${year}-${formattedMonth}-${formattedDay}`
}




// keep in sync with `accounts.db.Salutation` ENUM in backend!
export const salutations = [
  { label: 'Herr', value: '181380000' },
  { label: 'Frau', value: '181380001' },
  { label: 'Divers', value: '181380002' }
]

// keep in sync with `accounts.db.RegisterReferrer` ENUM in backend!
export const registerReferrers = [
  { label: '', value: '' },
  {
    label: 'Handball.net-Kanal (Social Media, Newsletter, Webseite, etc.)',
    value: '181380007'
  },
  {
    label: 'DHB-Kanal (Social Media, Newsletter, Webseite, etc.)',
    value: '181380006'
  },
  { label: 'Landesverband', value: '181380003' },
  { label: 'Verein', value: '181380002' },
  { label: 'Medien', value: '181380005' },
  { label: 'Gewinnspiel', value: '181380004' },
  { label: 'Freunde / Bekannte', value: '181380001' },
  { label: 'Sonstiges', value: '181380000' }
]

// keep in sync with `accounts.db.Role` ENUM in backend!
export const userRoles = [
  { label: 'Betreuer*in', value: '4' },
  { label: 'Ehrenamtliche*r', value: '5' },
  { label: 'Fan', value: '6' },
  { label: 'Hauptamtliche*r', value: '9' },
  { label: 'Schiedsrichter*in', value: '3' },
  { label: 'Spieler*in', value: '1' },
  { label: 'Sonstige', value: '7' },
  { label: 'Trainer*in', value: '2' },
  { label: 'Zeitnehmer*in', value: '8' }
]

// keep in sync with `accounts.db.Organization` ENUM in backend!
export const organizations = [
  { label: 'Baden', value: '181380000' },
  { label: 'Bayern', value: '181380001' },
  { label: 'Berlin', value: '181380002' },
  { label: 'Brandenburg', value: '181380003' },
  { label: 'Hamburg', value: '181380004' },
  { label: 'Hessen', value: '181380005' },
  { label: 'Mecklenburg-Vorpommern', value: '181380006' },
  { label: 'Niedersachsen-Bremen', value: '181380008' },
  { label: 'Nordrhein', value: '181380007' },
  { label: 'Pfalz', value: '181380009' },
  { label: 'Rheinhessen', value: '181380010' },
  { label: 'Rheinland', value: '181380011' },
  { label: 'Saar', value: '181380012' },
  { label: 'Sachsen', value: '181380013' },
  { label: 'Sachsen-Anhalt', value: '181380019' },
  { label: 'Schleswig Holstein', value: '181380014' },
  { label: 'Südbaden', value: '181380015' },
  { label: 'Thüringen', value: '181380016' },
  { label: 'Westfalen', value: '181380017' },
  { label: 'Württemberg', value: '181380018' }
]

// keep in sync with `accounts.db.ClothingSize` ENUM in backend!
// export const clothingSizes = [
//   { label: '116', value: '181380013' },
//   { label: '128', value: '181380012' },
//   { label: '140', value: '181380011' },
//   { label: '152', value: '181380010' },
//   { label: '164', value: '181380009' },
//   { label: '176', value: '181380008' },
//   { label: 'XS', value: '181380007' },
//   { label: 'S', value: '181380000' },
//   { label: 'M', value: '181380001' },
//   { label: 'L', value: '181380002' },
//   { label: 'XL', value: '181380003' },
//   { label: 'XXL', value: '181380004' },
//   { label: '3XL', value: '181380005' },
//   { label: '4XL', value: '181380006' }
// ]

// keep in sync with `accounts.db.NationalPlayer` ENUM in backend!
export const nationalPlayers = [
  { label: 'ANTL, Lisa', value: '181380019' },
  { label: 'BEHNKE, Julia', value: '181380020' },
  { label: 'BEHREND, Jenny', value: '181380021' },
  { label: 'BENEKE, Max Christoph', value: '181380022' },
  { label: 'BERGER, Amelie', value: '181380023' },
  { label: 'BIRLEHM, Joel', value: '181380024' },
  { label: 'BLECKMANN, Dana', value: '181380025' },
  { label: 'BÖLK, Emily', value: '181380026' },
  { label: 'DAHMKE, Rune', value: '181380002' },
  { label: 'DÖLL, Antje', value: '181380027' },
  { label: 'ENGEL, Nina', value: '181380028' },
  { label: 'FILTER, Katharina', value: '181380029' },
  { label: 'FISCHER, Justus', value: '181380016' },
  { label: 'FREIHÖFER, Tim', value: '181380030' },
  { label: 'GOLLA, Johannes', value: '181380017' },
  { label: 'GRGIC, Marko', value: '181380031' },
  { label: 'GRIJSEELS, Alina', value: '181380032' },
  { label: 'GROETZKI, Patrick', value: '181380014' },
  { label: 'HANNE, Martin', value: '181380004' },
  { label: 'HAUF, Alexia', value: '181380033' },
  { label: 'HEYMANN, Sebastian', value: '181380034' },
  { label: 'HORNKE, Tim', value: '181380035' },
  { label: 'HUHNSTOCK, Jolina', value: '181380036' },
  { label: 'HURST, Isabell', value: '181380037' },
  { label: 'KASTENING, Timo', value: '181380015' },
  { label: 'KNORR, Juri', value: '181380008' },
  { label: 'KOHLBACHER, Jannik', value: '181380018' },
  { label: 'KÖSTER, Julian', value: '181380006' },
  { label: 'KÜHNE, Nieke', value: '181380057' },
  { label: 'LEUCHTER, Viola', value: '181380038' },
  { label: 'LICHTLEIN, Nils', value: '181380009' },
  { label: 'LOTT, Annika', value: '181380039' },
  { label: 'MAIDHOF, Julia', value: '181380040' },
  { label: 'MERTENS, Lukas', value: '181380003' },
  { label: 'MICHALCZIK, Marian', value: '181380010' },
  { label: 'NOTHDURFT, Tim', value: '181380041' },
  { label: 'REBMANN, Daniel', value: '181380042' },
  { label: 'REINEMANN, Toni-Luisa', value: '181380043' },
  { label: 'SCHMELZER, Meike', value: '181380044' },
  { label: 'SEMPER, Franz', value: '181380045' },
  { label: 'SMITS, Xenia', value: '181380046' },
  { label: 'SPÄTH, David', value: '181380000' },
  { label: 'STEFFEN, Marie', value: '181380047' },
  { label: 'STEINERT, Christoph', value: '181380013' },
  { label: 'STUTZKE, Lukas', value: '181380048' },
  { label: 'THOMAIER, Mareike', value: '181380049' },
  { label: 'TRÖSTER, Sabrina', value: '181380050' },
  { label: 'USCINS, Renars', value: '181380012' },
  { label: 'VON PEREIRA , Aimée', value: '181380051' },
  { label: 'WACHTER, Sarah', value: '181380052' },
  { label: 'WEISS, Marie', value: '181380053' },
  { label: 'WITZKE, Luca', value: '181380054' },
  { label: 'WOLFF, Andreas', value: '181380001' },
  { label: 'ZECHEL, Tim', value: '181380055' },
  { label: 'ZERBE, Lukas', value: '181380056' }
]
